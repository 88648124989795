* { font-family: "Roboto"; font-weight: 300;  }
.green { color: #76DE7A; }
.red { color: #BE1E2D; }

.spacing_top_small { margin-top: 7px !important; }
.spacing_top_medium { margin-top: 14px !important; }
.spacing_top_large { margin-top: 21px !important; }
.spacing_left_small { margin-left: 7px !important; }
.spacing_left_medium { margin-left: 14px !important; }
.spacing_left_large { margin-left: 21px !important; }
.spacing_left_xlarge { margin-left: 35px !important; }

body { background: white; color: rgba(0,0,0,.84); padding: 0; margin: 0; }
#root { margin: 0; }

footer { position: relative; margin-top: 84px; padding: 28px 40px 42px 251px; background: #343438; color: #ccc; z-index: 21; font-size: 0.75rem; }
footer a { color: #ddd; padding-right: 21px; }

nav { position: fixed; top: 0; bottom: 0; left: 0; width: 208px; height: 100%; /* background: #f2f5f8; */ background: rgba(242, 245, 248, .5); border-right: 1px solid rgba(0,0,0,.07); }
#logo { position: absolute; top: 21px; left: 21px; right: 21px; height: 21px; }
#logo svg { max-width: 140px; }
/* #nav { display: inline-block; padding: 42px 21px 14px; color: #283b5a; } */
#nav ul { position: absolute; top: 97px; left: 31px; right: 21px; list-style-type: none; padding: 14px 0; }
#nav ul li {  }
#nav ul li span { display: block; font-size: .9rem; padding: 10px 0 10px 14px; font-weight: 500; font-weight: 500; }
#nav ul li a { display: block; font-size: .9rem; padding: 10px 0 10px 14px; font-weight: 500; text-decoration: none; color: #283b5a; font-weight: 500; }
#nav ul li:not(.active) i, #nav ul li:not(.active) a  { opacity: 0.75; }
#nav ul li:not(.active):hover i, #nav ul li:not(.active):hover a { opacity: .9; }
#nav ul li.active i { opacity: 1; color: black; }
#nav ul li.active { color: black; }
#nav ul li svg { color: #283b5a; display: inline-block; width: 14px; margin-left: -14px; margin-right: 10px; }
nav ul li a span.badge.rounded-pill.bg-danger { position: relative; top: -5px; left: 4px; }

.btn.btn-link.dropdown-toggle { color: #000; text-decoration: none; }

#version { position: absolute; bottom: 14px; left: 35px; right: 21px; font-size: 0.66rem; line-height: 1; }

.form-control { width: 100%; padding: .375rem .75rem; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #212529; background-color: #fff; background-clip: padding-box; border: 1px solid #ced4da; -webkit-appearance: none; -moz-appearance: none; appearance: none; border-radius: .25rem; transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }
div.form-control { position: relative; border: none; z-index: 3; background: none; }

div.form-control:empty:before{ content: 'Search all cases…'; color: #888; pointer-events: none; display: block; /* For Firefox */ }

#utilities { position: relative; display: inline-block; left: 208px; width: calc(100% - 208px); padding: 21px 0 14px; }
#utilities .input-group { position: relative; display: flex; align-items: stretch; width: 60%; padding-left: 35px; }
#utilities .input-group .input-group-text { display: flex; align-items: center; padding: .375rem .75rem; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #212529; text-align: center; white-space: nowrap; }
#utilities .input-group svg { color: rgba(40,59,90,.37); }
#utilities input { position: relative; border: none; padding-left: 9px; font-family: "Roboto"; font-weight: 500; }
#utilities input::placeholder { color: #aeaeae; font-family: "Roboto"; font-weight: 500; }
#utilities input:focus-visible, #utilities div.form-control:focus-visible { outline: none; }
#utilities .input-group-text { font-family: "FontAwesome"; color: #aeaeae; font-size: 1rem; background:none; border: none; }
#utilities input:active, #search input:focus { outline:none !important; }

#suggestedInputs { display: none; position: absolute; top: 44px; left: 28px; box-shadow: 3px 2px 5px rgba(0,0,0,.3); background: white; z-index: 19; padding: 21px 240px 21px 21px; font-size: .9rem; border-radius: 5px; }
#suggestedInputs.active { display: block; }
#suggestedInputs .suggestedHeader { font-weight: 500; color: #888; margin-bottom: 7px; }
#suggestedInputs .suggestion { padding: 5px 0; color: #888; line-height: .9; margin: 12px 0; }
#suggestedInputs .suggestion .walkthrough { border-radius: 3px; background-color: #ccc; color: white; padding: 3px; }

#utilities .utilities_navigation { display: flex; position: absolute; top: 24px; right: 24px; font-size: .9rem; color: #888; }
#utilities .utilities_navigation div:not(.presenceDot) { padding: 7px 35px 7px 21px; font-weight: 500; cursor: pointer; position: relative; }
#utilities .utilities_navigation div:not(.presenceDot) .dot { position: absolute; display: inline-block; width: 12px; height: 12px; background: red; color: white; font-size: .8rem; font-weight: 500; border-radius: 12px; padding: 3px; text-align: center; line-height: 12px; margin: 0 0 3px 7px;}
#utilities .utilities_navigation div:not(.presenceDot):hover .dot { color: white; }
#utilities .utilities_navigation div:not(.presenceDot):hover { color: #666;}
#utilities .utilities_navigation div.presenceDot { margin-top: -11px; }
#utilities .utilities_navigation div.presence { padding-right: 14px; }
#utilities .utilities_navigation .dropdown.active .dropdown-menu { display: block; }
#utilities .utilities_navigation .dropdown .dropdown-menu { display: none; position: absolute; top: 21px; right: 0; width: 120px; list-style-type: none; background: white; border: 1px solid #ccc; padding-left: 0; z-index: 999; }
#utilities .utilities_navigation .dropdown .dropdown-menu li a, #utilities .utilities_navigation .dropdown .dropdown-menu li span { display: block; color: black; font-size: 0.8rem; font-weight: 500; text-decoration: none; padding: 7px 14px; }
#utilities .utilities_navigation .dropdown .dropdown-menu li .dropdown-header { color: #888; }


.greeting { margin-top: 42px; margin-left: 49px; }
.nav_name { font-size: 2rem; line-height: 1; font-weight: 500; }
.nav_lastsignedon { padding-top: 0.66rem; }

.widget.opencases canvas { background-image: url("scatter_background.png"); background-size: contain; }


.dashboard { border-top: 1px solid rgba(40, 59, 90, .15); /* box-shadow: 0 10px 20px rgba(0,0,0,0.04), 0 8px 10px rgba(0,0,0,0.08); border-radius: 9px; */ }
.dashboard .visualizer { background-color: rgba(242, 245, 248, .5); border-top-left-radius: 9px; border-top-right-radius: 9px; display: flex; flex-direction: row; justify-content: space-around; padding-top: 21px; }
.dashboard .visualizer .datavis { text-align: center;  }
.dashboard .visualizer .datavis canvas { margin-bottom: 8px; }
.dashboard .visualizer .datavis .data { font-size: 3rem; color: #000; padding-top: 2rem; }
.dashboard .visualizer .datavis .caption { color: #000; }

.controls { position: relative; text-align: right; padding-top: 42px; padding-bottom: 5px; }
.controls .control_state { position: absolute; left: 42px; display: inline-block; font-size: 1.1rem; font-weight: 500; }
.controls .control_state .control_state_change { font-size: 0.75rem; text-decoration: underline; margin: 0 14px; display: inline-block; cursor: pointer; }
.controls .control { position: relative; display: inline-block; border-radius: 3px; color: #888; font-size: 0.75rem; font-weight: 500; padding: 7px 21px; margin: 7px; cursor: pointer; }
.controls .control .control_icon { position: absolute; left: 0; width: 14px; }
.control:last-of-type { margin-right: 14px; }
.control.active { }

section { margin-top: 21px; margin-left: 209px; border-bottom: 1px solid rgba(40, 59, 90, .15); }
section.no_bottom_border { border-bottom: none; }
section .text { padding: 21px 42px;  }
.section_title { font-size: 1.4rem; font-weight: 500; color: #000; }
.section_caption { font-size: 0.9rem; line-height: 1.5; font-weight: 200; color: #000; }


section .text.twoColumn { display: flex; justify-content: space-between; }
.actions .button { position: relative; background: none; text-decoration: none; color: black; border: 1px solid black; padding: 7px 10px 7px 35px; margin-top: 16px; font-size: .75rem; font-weight: 500; cursor: pointer; }
.actions .button.no_icon { padding-left: 10px; }
.actions .button:hover { background: #f5f5f5; }
.actions .button.add_new_case::before { position: absolute; content: '\f067'; font-family: 'Font Awesome 5 Free'; font-weight: 900; top: calc(50% - 0.45rem); left: 14px; color: #000; font-size: .8rem; line-height: 1rem; }

#showClosed, #configureView { padding-left: 28px; position: relative; }


.dashboard .controls .form-check { padding-right: 21px; }
.dashboard .controls .form-check label { color: rgba(0,0,0,.85); font-size: .66rem; line-height: 1.6rem; }

.dashboard.placeholder { background: #dedede; min-height: 300px; }

.table_holder { border-bottom: 1px solid rgba(40, 59, 90, .15); padding: 7px 0 35px; }
.dashboard_table { width: 100%; margin: 0; background: white; border-spacing: 0; background-color: rgba(165, 175, 182, .35); }
.dashboard_table thead tr { background-color: rgba(165, 175, 182, .4); min-height: 35px; } /* rgba(242, 245, 248, .66); }*/ /* db5c2c or 6aafd6*/
.dashboard_table thead tr th { padding: 8px 4px 8px 7px; margin: 0; font-size: 0.675rem; line-height: 1.5; font-weight: 500; color: black; cursor: pointer; text-align: left; }
.dashboard_table thead tr th:hover { /* background: rgba(242, 245, 248, 1); */ }
.dashboard_table thead tr th:first-of-type { border-left: none !important; }
.dashboard_table thead tr th:last-of-type {  }
.dashboard_table tbody tr td { font-size: 0.8rem; padding: 10px 4px 10px 7px;  }
.dashboard_table_clean { background: transparent !important; }
.dashboard_table_clean thead tr th { cursor: inherit; }
.dashboard_table:not(.dashboard_table_clean) tbody tr:nth-of-type(odd) { background: white; }
.dashboard_table:not(.dashboard_table_clean) tbody tr:nth-of-type(even) { background: #f5f5f5; }
.dashboard_table tbody tr.featured .case_number, #dashboard_table tbody tr.featured .respondent_name { font-weight: 500; }

.dashboard_table.hasAction tr:not(.tablehead) { cursor: pointer; }
.dashboard_table.hasAction tr:not(.tablehead):hover { background-color: #eaeaea; }
.dashboard_table.hasAction tr.ncase:nth-child(even):hover { background: #f2f5f8; }
.dashboard_table.hasAction tr.ncase:nth-child(odd):hover { background: #f0f3f6; }


.dashboard_table tr:after { border-top: 1px solid rgba(0,0,0,.2); content: " "; top: -1px; position: absolute; left: 0; right: 0; }
.dashboard_table tr.case { border-bottom: 1px solid #f2f5f8; border-top: 1px solid red; margin-bottom: 1px; position: relative; }

.dashboard_table tr.case.active td { background-color: #f2f5f8; } /* eef1f4; } */

.dashboard_table tr.case.closed { display: none; }
.dashboard_table tr.case.closed.showanyway { display: table-row; }
.dashboard_table tr.case.closed.showanyway td { opacity: 0.7; }
.dashboard_table tr.case td.rowcheckbox {  }
.dashboard_table tr.case td.case_status { text-align: center; font-size: 1.5rem; line-height: 0.9; }
.dashboard_table tr.case td.case_status { color: #66ff66;  } /* background-color: #c8f1d0; color: #1d5c29; } */
.dashboard_table tr.case.closed td.case_status { color: #999; }


.dashboard .calendar_holder { }
.dashboard .calendar_holder .calendar_week { width: 100%; padding: 7px 0 35px; margin: 0; background: white; border-radius: 9px; }
.dashboard .calendar_holder .calendar_week thead tr { background-color: rgba(242, 245, 248, .66); padding: 4px 7px 14px; }
.dashboard .calendar_holder .calendar_week thead tr th.weekend { background-color: rgba(0, 59, 90,.315); color: rgba(0, 59, 90,.5); }
.dashboard .calendar_holder .calendar_week tr td.weekend { background-color: rgba(0, 59, 90,.2); color: rgba(0, 59, 90,.5); }
.dashboard .calendar_holder .calendar_week thead tr th { padding: 9px 6px; margin: 0; font-size: 0.66rem; font-weight: 500; color: #283b5a; border-left: 1px solid rgba(40, 59, 90,.315); border-top: 1px solid rgba(40, 59, 90, .15); border-bottom: 1px solid rgba(40, 59, 90, .15); cursor: pointer; }
.dashboard .calendar_holder .calendar_week thead tr th:hover { background: rgba(242, 245, 248, 1); }
.dashboard .calendar_holder .calendar_week thead tr th:first-of-type { border-left: none !important; }
.dashboard .calendar_holder .calendar_week thead tr th:last-of-type {  }
.dashboard .calendar_holder .calendar_week td.day { font-size: 2rem; font-weight: 100; border-top: 1px solid rgba(40, 59, 90, .15); border-left: 1px solid rgba(40, 59, 90, .15); padding: 4px 7px 10px; }
.dashboard .calendar_holder .calendar_week td.day:last-of-type { border-right: 1px solid rgba(40, 59, 90, .15); }
.dashboard .calendar_holder .calendar_week tr td.day_detail.weekend { background-color: rgba(0, 59, 90,.2); color: rgba(0, 59, 90,.5); }
.dashboard .calendar_holder .calendar_week td.day_detail { font-size: 0.75rem; font-weight: 500; border-bottom: 1px solid rgba(40, 59, 90, .15); border-left: 1px solid rgba(40, 59, 90, .15); padding: 4px 7px 10px;  }

.dashboard .card_holder { display: flex; }
.dashboard .card_holder .card { margin: 14px; width: 28%; max-width: 240px; }
.dashboard .card_holder .card .btn-primary { color: #fff; background-color: #283b5a; border-color: #283b5a; }

.caption { font-size: 0.66rem; color: #afb8c4; }
.glance tr td caption { display: none; font-size: 0.66rem !important; color: #afb8c4; }
.glance tr td p { margin: 0; }
.glance tr td { text-align: center !important; }


.btn-simple { background: #f5f5f5; border: 1px solid rgba(0,0,0,.5); color: #333; cursor: pointer; opacity: 0.8; }
.btn-warn { background: rgba(225,25,25,.8); border: 1px solid rgba(0,0,0,.1); color: white; cursor: pointer; opacity: 0.8; }
.btn-simple:hover, .btn-warn:hover { opacity: 1; }


#case_record_navigation { margin-bottom: 30px; }
#case_record { margin-top: 42px; padding-left: 0; }
#case_record.closed { opacity: .8; }
#case_record .header_entry { border-bottom: 1px solid rgba(0,0,0,.1); }
#case_record .case_details_labels { font-size: 0.75rem; margin-top: 21px; margin-bottom: 21px; }
#case_record .case_details_labels .subheader, .subheader.initial { font-weight: 500; opacity: 0.8;  }
.subheader.initial { margin-left: 49px; font-size: 0.75rem; margin-top: 42px; }
.greeting {  }
#case_record .case_number { font-size: 2rem; line-height: 1; font-weight: 500; }
#case_record .case_name { font-size: 1.4rem; font-weight: 300; }
#case_record .case_status { margin-top: 19px; }
#case_record .case_status .badge.bg-secondary { background-color: rgba(0,0,0,.2) !important; font-weight: 300; }
#case_record .field-label { text-align: right; padding-right: 14px; color: #666; font-size: 0.75rem; }

#case_history { margin-left: 49px; margin-right: 21px; }
#case_history .history_entry { font-size: 0.875rem; padding: 7px 0; border-bottom: 1px solid rgba(0,0,0,.1); }


#case_detail { position: absolute; top: 161px; left: 208px; right: 0; bottom: 0; border-top: 1px solid rgba(0,0,0,.3); padding: 0 35px 35px; background: white; z-index: 3; }



aside.orange_wash { position: fixed; top: 0; left: 0; bottom: 0; right: 50%; background: linear-gradient(0deg, rgba(219,92,44,1) 48%, rgba(219,92,44,0.7483368347338936) 100%); }
aside.orange_wash div { max-width: 220px; margin: 30% auto; width: 220px; }
aside.orange_wash div svg { max-width: 100%;  }
aside.orange_wash div p { color: white; font-family: "Roboto"; font-size: 1.2rem; font-weight: 200; padding-left: 54px; margin-right: -100px; }
.loginWrapper { position: absolute; top: 0; right: 0; bottom: 0; left: 50%; background: #f5f5f5; }
.loginWrapper .loginBox { margin: 30% auto; width: 60%; }

select.dataField { border: none; padding: 2px 0 3px; margin-left: -3px; width: 150px; max-width: 100%; }
.sectionWrapper section .inputWrapper input:not(.button), .sectionWrapper section .inputWrapper textarea { border: none; padding: none; }
.sectionWrapper section .inputWrapper input::placeholder, .sectionWrapper section .inputWrapper textarea::placeholder { color: #aaa; }
.sectionWrapper section .inputWrapper input.respondentName { font-size: 1.4rem; }
.sectionWrapper section .inputWrapper textarea { width: 100%; min-height: 240px; border: 1px solid #eaeaea; margin: 3px -7px; padding: 7px 5px; }

.caseDetails.space_above { margin-top: 3rem;}
.big_space_below { margin-bottom: 9rem; }

.irWrapper { margin-left: 0; padding: 42px 21px; border-bottom: 1px solid #eaeaea; }
.irWrapper .inputWrapper, form section .buttonWrapper { display: block; width: 1000px; max-width: 90%; margin: 14px auto; }
.irWrapper .inputGroup { padding: 4px 0; display: flex; }
.irWrapper .inputGroup label { display: inline-block; width: 30%; text-align: right; padding-right: 14px; color: #999; font-size: 0.75rem; font-weight: 500; margin-top: 10px; }
.irWrapper .inputGroup input, form .inputGroup select, form .inputGroup textarea { width: 550px; padding: 7px 10px; border: 1px solid #ccc; }
.irWrapper .inputGroup #character_count { width: calc(30% + 585px); font-size: 0.75rem; text-align: right; color: #aaa; }
.irWrapper .inputGroup.narrow select, form .inputGroup.narrow input { width: 250px; }


.sectionWrapper { display: flex; width: calc(100% - 209px); flex-direction: row; margin-left: 209px;  }
.sectionWrapper section.colLeft { position: relative; width: calc(100% - 420px); margin: 0; }
.sectionWrapper section.colRight { position: relative; width: 420px; margin: 0; }
.textWrapper, .sectionWrapper section.colLeft .textWrapper, .sectionWrapper section.colLeft .inputWrapper { padding: 21px 0 14px 42px; }
.sectionWrapper section.colRight .buttonWrapper { display: flex; flex-direction: row; padding-right: 21px; vertical-align: bottom; }
.sectionWrapper section.colRight .buttonWrapper a { text-decoration: none; }
.sectionWrapper section.colRight .buttonWrapper .button {  font-size: 0.75rem; font-weight: 500; padding: 3rem 7px 0 14px; color: #888; }

.buttonWrapper .button { cursor: pointer; }
.buttonWrapper .button.case_notes::before {content: '\f15c'; font-family: 'Font Awesome 5 Free'; font-weight: 900; padding-right: 7px; color: #888; font-size: .8rem; line-height: 1rem; }
.buttonWrapper .button.case_share::before {content: '\f14d'; font-family: 'Font Awesome 5 Free'; font-weight: 900; padding-right: 7px; color: #888; font-size: .8rem; line-height: 1rem; }
.buttonWrapper .button.case_print::before {content: '\f1c1'; font-family: 'Font Awesome 5 Free'; font-weight: 900; padding-right: 7px; color: #888; font-size: .8rem; line-height: 1rem; }

.buttonGroup button, .buttonGroup input.button { background: white; border: 1px solid #000; font-size: 0.75rem; font-weight: 500; padding: 5px 8px; margin: 0 10px 0 0; border-radius: 3px; cursor: pointer; }

section.notes_section { margin: 21px 60px 0;}
.sectionWrapper.notes_section { display: flex; width: 100%; flex-direction: row; margin: 0; }
section.notes_section.colLeft { padding-left: 60px; width: calc(70% - 60px); }
.sectionWrapper.notes_section section.colRight .buttonWrapper { justify-content: flex-end; padding-right: 60px; }


.caseHeader { position: relative; padding: 21px 42px; }
.caseHeader .back { display: inline-block; max-width: 40px; padding-right: 21px; font-size: 1.2rem; color: #222; }
.caseHeader .back a:active { color: #000; }
.caseHeader .exit { position: absolute; right: 84px; top: 21px; font-size: 1.5rem; }
.caseHeader .caseNumber { display: inline-block; }


.colLeft.caseDetailContainer { border-right: 1px solid rgba(40, 59, 90, .15);  }
.colLeft .leftWrapper { position: relative; padding: 35px 21px 35px 42px;  }
.colLeft .leftWrapper.case_stage { border-left: 6px solid #CCC; border-bottom: 1px solid rgba(40, 59, 90, .15); }
.colLeft .leftWrapper.case_stage.intake { border-left-color: #FBB15F; }
.colLeft .leftWrapper.case_stage.intake .wrapperHeader { color: #FBB15F; }
.colLeft .leftWrapper.case_stage.pi { border-left-color: #DB5C2C; }
.colLeft .leftWrapper.case_stage.pi .wrapperHeader { color: #DB5C2C; }
.colLeft .leftWrapper.case_stage.investigation { border-left-color: #BE2034; }
.colLeft .leftWrapper.case_stage.investigation .wrapperHeader { color: #BE2034; }
.colLeft .leftWrapper.case_stage.inactive { border-left-color: #eaeaea !important; }
.colLeft .leftWrapper.case_current_disposition { background-color: #FAFAFA; }

.colLeft .leftWrapper .wrapperHeader { font-weight: 500; font-size: 1rem; margin-bottom: 0.3rem; }


.sectionWrapperFull { width: calc(100% - 212px); margin-left: 209px;  }
.sectionWrapperFull .media_library { background: #F5F5F5; }
.sectionWrapperFull section.colFull { position: relative; margin: 0; }
.sectionWrapperFull section.colFull .textWrapper, .sectionWrapperFull section.colFull .inputWrapper { padding: 21px 0 14px 42px; }
.sectionWrapperFull section.colFull .wrapper { position: relative; padding: 35px 21px 35px 42px;  }
.sectionWrapperFull section.colFull .wrapperHeader .label { font-weight: 500; font-size: 1rem; margin-bottom: 0.3rem; }


.media_library .wrapper .library_utilities { display: flex; }
.media_library .wrapper .library_utilities .library_views { width: 35%; display: flex; padding: 35px 0 21px;  }
.media_library .wrapper .library_utilities .library_views .folder_view, .media_library .wrapper .library_utilities .library_views .timeline_view { position: relative; font-weight: 300; font-size: .875rem; color: #000; padding-left: 21px; padding-right: 35px; }
.media_library .wrapper .library_utilities .library_views .folder_view::after { position: absolute; top: 2px; left: 0; width: 35px; content: '\f0ce'; font-family: 'Font Awesome 5 Free'; font-weight: 900; color: black; font-size: .8rem; line-height: 1rem; }
.media_library .wrapper .library_utilities .library_views .timeline_view::after { position: absolute; top: 2px; left: 0; width: 35px; content: '\f017'; font-family: 'Font Awesome 5 Free'; font-weight: 900; color: black; font-size: .8rem; line-height: 1rem; }
.media_library .wrapper .library_utilities .file_search { width: 65%; padding: 28px 0 21px; }
.media_library .wrapper .library_utilities .file_search .input-group .form-control { background-color: white; }

.media_library .wrapper .library_utilities.tighten_library_utilities .library_views { width: 46%; }
.media_library .wrapper .library_utilities.tighten_library_utilities .file_search { width: 54%; padding: 7px 0; }


.media_library .input-group { position: relative; display: flex; align-items: stretch; padding-left: 35px; }
.media_library .input-group .input-group-text { display: flex; align-items: center; padding: .375rem .75rem; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #212529; text-align: center; white-space: nowrap; }
.media_library .input-group svg { color: rgba(40,59,90,.37); }
.media_library input { position: relative; border: none; padding-left: 9px; font-family: "Roboto"; font-weight: 500; }
.media_library input::placeholder { color: #aeaeae; font-family: "Roboto"; font-weight: 500; }
.media_library input:focus-visible, #utilities div.form-control:focus-visible { outline: none; }
.media_library .input-group-text { font-family: "FontAwesome"; color: #aeaeae; font-size: 1rem; background:none; border: none; }
.media_library input:active, #search input:focus { outline:none !important; }

.media_library .wrapper .wrapperHeader { display: flex; justify-content: space-between; padding-right: 42px; }
.media_library .wrapper .wrapperHeader .label, .media_library .wrapper .wrapperHeader .callout { }
.media_library .wrapper .wrapperHeader .callout { position: relative; font-weight: 500; font-size: 0.75rem; color: #888; padding-left: 42px; }
.media_library .wrapper .wrapperHeader .callout::after { position: absolute; top: 1px; left: 0; width: 35px; content: '\f05a'; font-family: 'Font Awesome 5 Free'; font-weight: 900; padding-left: 14px; color: #888; font-size: 0.875rem; line-height: 1rem;  }

.media_library .wrapper .library_utilities .file_search .input-group div.form-control:empty:before{ content: 'Search all files…'; color: #888; pointer-events: none; display: block; /* For Firefox */ }

.caseFileWrapper { margin-left: -42px; }
.fileManagerTabWrapper { }
.fileManagerTabWrapper .fileManagerTab { display: inline-block; padding: 10px 14px 10px 7px; border: 3px solid white; margin-right: -2px; font-weight: 500; font-size: 1rem;  }
.fileManagerTabWrapper .fileManagerTab:first-of-type { padding-left: 36px; }
.fileManagerTabWrapper .fileManagerTab.active { background: white; }
.fileManager { background: white; width: 100%; padding: 14px 0 21px; }
.fileManager thead th { text-align: left; font-size: 0.75rem; font-weight: 500; color: #888; padding: 4px 7px; }
.fileManager thead th:first-of-type { padding-left: 42px; }
.fileManager tbody tr:hover { background-color: #f5f5f5; cursor: pointer; }
.fileManager tbody tr td { font-size: .875rem; font-weight: 400; padding: 10px 7px; }
.fileManager tbody tr td.filename { position: relative; padding-left: 80px; }
.fileManager tbody tr td.filename::after { position: absolute; top: 10px; left: 29px; width: 35px; content: '\f15b'; font-family: 'Font Awesome 5 Free'; font-weight: 900; padding-left: 14px; color: black; font-size: 1.1rem; line-height: 1rem;  }
.fileManager tbody tr.application\/pdf td.filename::after { content: '\f1c1'; }
.fileManager tbody tr.image\/jpeg td.filename::after { content: '\f1c5'; }
.fileManager tbody tr.video\/mpeg td.filename::after { content: '\f1c8'; }
.fileManager tbody tr.application\/msword td.filename::after { content: '\f1c2'; }

.createNewDocument { position: relative; background: white; font-size: .875rem; font-weight: 400; padding: 21px 7px 21px 82px; cursor: pointer; margin-top: 6px; }
.createNewDocument:hover { background-color: #f5f5f5; }
.createNewDocument::after { position: absolute; top: 23px; left: 29px; width: 35px; content: '\f477'; font-family: 'Font Awesome 5 Free'; font-weight: 900; padding-left: 14px; color: black; font-size: 1.1rem; line-height: 1rem; }




.colLeft .leftWrapper .twoColumn { display: flex; flex-direction: row; }
.colLeft .leftWrapper .twoColumn div:nth-child(1) { width: 50%; }
.colLeft .leftWrapper .twoColumn div:nth-child(2) { width: 40%; padding-left: 10%; }
.colLeft .leftWrapper .twoColumn div:nth-child(1) div, .colLeft .leftWrapper .twoColumn div:nth-child(2) div { padding: 0 !important; width: 100%; }
.dataSet .dataHeader { font-weight: 300; color: #666666; font-size: .75rem; margin-top: 1rem;  }
.dataSet .dataField, .dataSet .dataField p { font-weight: 400; color: black; font-size: 1rem; margin-top: 0.1rem; line-height: 1.5; }
.dataSet .dataField p { }
textarea.dataField[name="incident_summary"] { width: 100%; min-height: 400px; border-color: #eaeaea; padding: 10px 7px; margin: 0 -7px; }
input.dataField { border: 1px solid #eaeaea; padding: 3px 7px; margin: 1px -7px; }
input.dataField.marginRight { margin-right: 3px; }
input.dataField:focus-visible { outline: 1px solid #bbb; }
input.dataField.error { color: red; border-color: red; }

.filtersWrapper { display: flex; width: 90%; }
.filtersWrapper .dataSet { padding-right: 3%; }
.filtersWrapper .dataSet .dataHeader { margin-top: 28px; }
.filtersWrapper .dataSet.col25 { width: 22%;  }
.filtersWrapper .dataSet.col50 { width: 47%; }
.filtersWrapper .dataSet input, .filtersWrapper .dataSet select { width: 100%; }
.dataFilter { font-weight: 500; color: black; font-size: .875rem; margin-top: .5rem; padding: 6px 3px; }


.colRight .rightWrapper { position: relative; padding: 28px 21px 28px 60px;  }
.wrapperHeader, .wrapperHeader div { font-weight: 500; font-size: 1rem; margin-bottom: 0.3rem; }
.colRight .rightWrapper .wrapperHeader div span.parenthetical { font-size: .8rem; font-weight: 300; }
.colRight .rightWrapper .wrapperHeader .wrapperHeaderAction { font-size: 0.5rem; text-decoration: underline; cursor: pointer; line-height: 1.8rem; }
.colRight .rightWrapper .wrapperNotice { font-size: 0.75rem; font-weight: 500; color: #555; margin-bottom: 0.3rem; }
.colRight .rightWrapper .wrapperAlert { font-size: 0.75rem; font-weight: 500; color: #911C27; margin-bottom: 0.3rem; }
.colRight .rightWrapper .wrapperAlert a { color: black; }

.colRight .rightWrapper .twoColumn { display: flex; flex-direction: row; }
.colRight .rightWrapper .twoColumn div:nth-child(1) { width: 45%; }
.colRight .rightWrapper .twoColumn div:nth-child(2) { width: 45%; padding-left: 10%; }

.colRight .rightWrapper.flagsWrapper { background-color: #efefef; }
.colRight .rightWrapper.flagsWrapper::before { content: "\f024"; font-size: 0.9rem; font-family: 'Font Awesome 5 Free'; font-weight: 900; position: absolute; left: 21px; top: 39px; }
.colRight .flagsWrapper .wrapperNotice:nth-child(2) { margin-top: 1rem;}


.colRight .rightWrapper.partiesWrapper::before { content: "\f2b9"; font-size: 0.9rem; font-family: 'Font Awesome 5 Free'; font-weight: 900; position: absolute; left: 21px; top: 39px; }
.colRight .rightWrapper.allegationsWrapper::before { content: "\f3ed"; font-size: 0.9rem; font-family: 'Font Awesome 5 Free'; font-weight: 900; position: absolute; left: 21px; top: 39px; }
.colRight .rightWrapper.relatedCasesWrapper::before { content: "\f02d"; font-size: 0.9rem; font-family: 'Font Awesome 5 Free'; font-weight: 900; position: absolute; left: 21px; top: 39px; }
.colRight .rightWrapper.personnelWrapper::before { content: "\f0c0"; font-size: 0.9rem; font-family: 'Font Awesome 5 Free'; font-weight: 900; position: absolute; left: 21px; top: 39px; }
.colRight .rightWrapper.historyWrapper::before { content: "\f017"; font-size: 0.9rem; font-family: 'Font Awesome 5 Free'; font-weight: 900; position: absolute; left: 21px; top: 39px; }
.colRight .rightWrapper.tasksWrapper::before { content: "\f0ae"; font-size: 0.9rem; font-family: 'Font Awesome 5 Free'; font-weight: 900; position: absolute; left: 21px; top: 39px; }
.colRight .rightWrapper.incidentsWrapper::before { content: "\f15c"; font-size: 0.9rem; font-family: 'Font Awesome 5 Free'; font-weight: 900; position: absolute; left: 21px; top: 39px; }


.colRight .rightWrapper.partiesWrapper .party, .colRight .rightWrapper.personnelWrapper .personnel, .colRight .rightWrapper.historyWrapper .history, .colRight .rightWrapper.incidentsWrapper .incident { margin: 0.2rem 0; padding: 0.1rem 0; }
.colRight .rightWrapper.partiesWrapper .party .partyRole, .colRight .rightWrapper.personnelWrapper .personnel .personnelRole, .colRight .rightWrapper.historyWrapper .history .occurance, .colRight .incidentsWrapper .incident { font-size: .75rem; font-weight: 300; }
.colRight .incidentsWrapper .incident { cursor: pointer; }
.colRight .rightWrapper.partiesWrapper .party .partyName, .colRight .rightWrapper.personnelWrapper .personnel .personnelName, .colRight .rightWrapper.historyWrapper .history .dateOccurred { font-size: .75rem; font-weight: 500; }
.colRight .rightWrapper.partiesWrapper .party .partyName.hasCallout { cursor: pointer; }
/* .colRight .rightWrapper.partiesWrapper .party .partyName.hasCallout::after { content: '\f055'; font-family: 'Font Awesome 5 Free'; font-weight: 900; padding-left: 14px; color: #888; font-size: .8rem; line-height: 1rem; } */

.colRight .rightWrapper.allegationsWrapper .allegation { font-size: 0.75rem; margin-top: 0.5rem; }

.colRight .rightWrapper.relatedCasesWrapper .relatedCases .relatedCase { font-size: .75rem; font-weight: 500; }
.colRight .rightWrapper.relatedCasesWrapper .relatedCases .relatedCase::after, .colRight .incidentsWrapper .incident::after { content: '\f35a'; font-family: 'Font Awesome 5 Free'; font-weight: 900; padding-left: 14px; color: #888; font-size: .8rem; line-height: 1rem; }
.colRight .rightWrapper.relatedCasesWrapper .relatedCases .relatedCaseActions { margin-top: -5px; }
.colRight .rightWrapper.relatedCasesWrapper .relatedCases .relatedCaseActions .relatedCaseAction { display: inline; margin-right: 12px; font-size: 0.66rem; text-decoration: underline; cursor: pointer; line-height: 1.8rem;  }


.parties_table { margin-top: 3rem; }
.dashboard_table.parties_table thead tr { background-color: #f2f2f2; min-height: 35px; } /* rgba(242, 245, 248, .66); }*/ /* db5c2c or 6aafd6*/
.dashboard_table.parties_table tr:not(.tablehead) { cursor: pointer; }
.dashboard_table.parties_table tr:not(.tablehead):hover { background-color: #eaeaea; }
.dashboard_table.parties_table tr.ncase:nth-child(even):hover { background: #f2f5f8; }
.dashboard_table.parties_table tr.ncase:nth-child(odd):hover { background: #f0f3f6; }
.dashboard_table.parties_table tr td.party_name { font-weight: 500; }
.dashboard_table.parties_table tr td a { color: black; text-decoration: none; }
.dashboard_table.parties_table tr.wrapperAlert * { color: #911C27; }
.dashboard_table.parties_table tr td.fixedWidthTD30P { width: 30%; padding-top: 3px; padding-bottom: 3px; }
.dashboard_table.parties_table tr td.actions { }
.dashboard_table.parties_table tr td.actions .text_button { background: none; border: none; padding: 3px 7px; text-decoration: underline; font-weight: 400; font-size: 0.75rem; }

.dashboard_table.parties_table tr.tr_separator td { background: #f2f2f2; padding: 42px 4px 8px 7px; font-size: 0.675rem; line-height: 1.5; font-weight: 500; color: black; }
.dashboard_table.parties_table tr.add_new td { background-color: #f2f2f2; padding: 8px 4px 8px 7px; }
.dashboard_table.parties_table tr.add_new td.party_name input.dataField { font-weight: 500;}
.dashboard_table.parties_table tr.add_new td input.dataField, .dashboard_table.parties_table tr.add_new td select.dataField { font-size: 0.8; padding: 7px; }
.dashboard_table.parties_table tr.add_new:hover td { background-color: #f2f2f2; }


.colRight .rightWrapper.historyWrapper .history.assignment * { color: #006787; }
.colRight .rightWrapper.historyWrapper .history.notStarted * { color: #aaa; }

.colRight .rightWrapper.tasksWrapper .task { margin-top: 21px; cursor: pointer; }
.colRight .rightWrapper.tasksWrapper .task .taskDetail { font-size: 0.75rem; font-weight: 300; position: relative; }
.colRight .rightWrapper.tasksWrapper .task .taskDetail::before { position: absolute; top: 2px; left: -35px; content: '\f111'; font-family: 'Font Awesome 5 Free'; font-weight: 300; color: #888; }
.colRight .rightWrapper.tasksWrapper .task .taskDueDate { font-size: .75rem; font-weight: 500; }

.colRight .rightWrapper.tasksWrapper .task.createTask { cursor: pointer; color: #aaa; }
.colRight .rightWrapper.tasksWrapper .task.createTask .taskDetail::before { position: absolute; top: 2px; left: -35px; content: '\f055'; font-family: 'Font Awesome 5 Free'; font-weight: 900; color: #888; }

.colRight .rightWrapper.partiesWrapper .party { margin-top: 3px; cursor: pointer; }
.colRight .rightWrapper.partiesWrapper .party.createParty { font-size: 0.75rem; font-weight: 300; position: relative; color: #aaa; padding-top: 10px; opacity: 0.5; transition: opacity .083s ease-in; }
.colRight .rightWrapper.partiesWrapper .party.createParty:hover { color: #999; }
.colRight .rightWrapper.partiesWrapper .party.createParty .partyDetail::before { position: absolute; top: 9px; left: -35px; content: '\f055'; font-family: 'Font Awesome 5 Free'; font-weight: 900; color: #aaa; }
.colRight .rightWrapper.partiesWrapper .party.createParty:hover .partyDetail::before { color: #999; }
.colRight .rightWrapper.partiesWrapper:hover .party.createParty { opacity: 1; }


.widgetTwoColumns section.colLeft { position: relative; width: 55%; margin: 0; }
.widgetTwoColumns section.colRight { position: relative; width: 45%; margin: 0; }

.widget { margin-top: 14px; background: none; }
.widgetWrapper { position: relative; padding: 35px 21px 35px 42px;  }
.widgetWrapper.fullWidth { width: 100%; }
.colRight .widgetWrapper { padding-left: 21px; }
.widget.dashboard_table thead tr { background: none; }
.widget.dashboard_table thead tr th { color: #888; }
.widget.dashboard_table tbody tr.atrisk { color: #911C27; }
.widget.dashboard_table tbody tr.atrisk td { font-weight: 400; }

.widget.dashboard_table tbody tr .taskActions { width: 48px; }
.widget.dashboard_table tbody tr.completed { text-decoration: line-through; color: #888; }
.widget.dashboard_table tbody tr .taskAction { position: relative; width: 24px; height: 24px; background: none; border: none; outline: none !important; cursor: pointer; }
.widget.dashboard_table tbody tr .taskAction::after { position: absolute; top: 6px; left: 6px; font-family: 'Font Awesome 5 Free'; font-weight: 300; color: #333; }
.widget.dashboard_table tbody tr .taskAction:focus-visible { outline: none !important; }
.widget.dashboard_table tbody tr .taskStatus::after { content: "\f111";  }
.widget.dashboard_table tbody tr.newtask .taskStatus::after { color: #ccc; }
.widget.dashboard_table tbody tr.completed .taskStatus::after { content: "\f058"; color: #888; }
.widget.dashboard_table tbody tr .taskEdit::after { content: "\f057"; color: #911C27; }
.widget.dashboard_table tbody tr .taskConfirm::after { content: "\f058"; color: #090; }
.widget.dashboard_table tbody tr .taskStart::after { content: "\f055"; font-weight: 900; color: #888; }
.widget.dashboard_table tbody tr td.taskCaseNumber { width: 93px; }
.widget.dashboard_table tbody tr td.taskCaseNumber select.dataField { font-size: 0.8rem; width: 91px; padding: 0; }
.widget.dashboard_table tbody tr td.taskDetail .dataField { width: 85%; font-size: 0.8rem; font-weight: 300; }
.widget.dashboard_table tbody tr td .taskDate.dataField { width: 60px; }

.opencases_view { min-height: 400px; }

.keyMetrics { margin: 14px -14px 14px -42px; padding: 35px 0; background-color: #777; display: flex; justify-content: space-around; color: white; }
.keyMetrics .keyMetric .value { font-weight: 300; font-size: 3rem; margin-bottom: 0.5rem;  }
.keyMetrics .keyMetric .label { font-weight: 500; font-size: 0.875rem; }
.keyMetrics .keyMetric .delta { font-weight: 300; font-size: 0.875rem; }

.caseStageHeader { padding: 81px 0 14px 102px; font-weight: 500; font-size: 1.2rem; }
section.casenoteWrapper { position:relative; display: flex; justify-content: space-around; align-items: stretch; border-bottom: none; margin: 0; }
.casenoteWrapper .casenoteDetails, .casenoteWrapper .caseNote { border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; margin-bottom: -1px; }
.casenoteWrapper .casenoteDetails { width: 220px; flex: 0 0 220px; line-height: 1.5; font-size: 0.925rem; font-weight: 400; transition: opacity 0.8s ease-out; opacity: 1; }
.casenoteWrapper .casenoteDetails.fresh { opacity: 0; }
.casenoteWrapper .casenoteDetails * { line-height: 1.5; font-size: 0.925rem; font-weight: 400; padding-left: 60px;}
.casenoteWrapper .casenoteDetails .caseLastEdited { font-size: 0.75rem; color: #888; }
.casenoteWrapper .caseNote { padding: 21px 60px 14px 14px; line-height: 1.5; font-size: 0.925rem; font-weight: 400; }
.casenoteWrapper .caseNote div { font-weight: 400; }
.casenoteWrapper .caseNote b { font-weight: 500; }
textarea.addNote.caseNote { width: 100%; border: none; /* margin-top: 7px;*/ min-height: 100px; padding-bottom: 42px; resize: none; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; }
textarea.addNote.caseNote:focus-visible { outline: none; }
.casenoteWrapper .caseNoteComments { position: relative; width: 410px; flex: 0 0 410px; }
.casenoteWrapper .caseNoteComments::after { position: absolute; content: ""; bottom: -1px; height: 1px; left: 0; width: 42px; background: #ccc; }
.casenoteWrapper .caseNoteComments::before { position: absolute; content: ""; top: 0; height: 1px; left: 0; width: 42px; background: #ccc; }
.casenoteWrapper .caseNoteComments .commentBar { position: absolute; top: 0; bottom: 0; width: 60px; cursor: pointer; }
.casenoteWrapper .caseNoteComments .commentBar::before { content: ""; position: absolute; top: 0; bottom: 0; width: 1px; left: 42px; background: #ccc; }
.casenoteWrapper .caseNoteComments .commentBar:hover::before { background: #888; }
.casenoteWrapper .caseNoteComments .commentBar:not(.hasComment)::after { position: absolute; bottom: 24px; left: 30px; width: 24px; height: 24px; border-radius: 24px; border: 1px solid #ccc; background: white; color: #eaeaea; font-weight: 900; content: '\f27a'; font-family: 'Font Awesome 5 Free'; font-size: 0.7rem; text-align: center; line-height: 24px; z-index: 3; }
.casenoteWrapper .caseNoteComments .commentBar:hover::after { border-color: #888; color: #888; }
.commentWrapper { position: relative; font-size: .8rem; width: calc(100% - 204px); padding: 0 21px 21px 21px; border: 1px solid black; left: 84px; margin-top: 7px; margin-bottom: 3px; }
.commentWrapper .comment { font-size: 0.875rem; line-height: 1.2; padding-bottom: 21px; }
.commentWrapper::before { content: ""; position: absolute; top: calc(38% + 12px); height: 1px; left: -42px; width: 42px; background: black; }
.commentWrapper::after { position: absolute; top: 38%; left: -56px; width: 24px; height: 24px; border-radius: 24px; border: 1px solid #000; background: white; color: #000; font-weight: 900; content: '\f27a'; font-family: 'Font Awesome 5 Free'; font-size: 0.7rem; text-align: center; line-height: 24px; z-index: 3; }
.commentAuthor { font-weight: 500; padding-top: 21px; }
.commentAuthor:not(:first-child) { margin-top: 14px; padding-top: 14px; border-top: 1px solid #ccc; }
.commentDate { font-size: 0.75rem; color: #888; margin-bottom: 10px; }
.comment_reply { resize: none; font-size: 0.75rem; background: white; color: #666; width: 100%; border: none; border-radius: 0; border-top: 1px solid black; padding: 14px; margin: -1px 0 -3px -21px; width: calc(100% + 14px); }
.comment_reply:focus-visible { outline: none; background: white; }
.comment_reply:empty { }
.commentWrapper .buttonGroup { text-align: right; }
.commentWrapper .buttonGroup .active { }
.commentWrapper .buttonGroup .inactive { opacity: 0.3; }

.presenceDotWrapper { position: absolute; right: 84px; top: 21px; font-size: 1.5rem; }
.presenceDot { display: inline-block; color: white; font-size: 1.1rem; line-height: 36px; width: 36px; height: 36px; border-radius: 30px; font-weight: 500; text-align: center; opacity: 0.85; cursor: pointer; }
.presenceDot:hover { opacity: 1; }


.modalOverlay { position: fixed; left: 0; right: 0; top: 0; bottom: 0; background: rgba(0,0,0,0.5); z-index: 99; }
.modalContent { position: relative; background: #EFEFEF; top: 50%; left: 50%; transform: translate(-50%, -50%) !important; width: 60%; padding: 3rem 1.5rem 1.5rem; box-shadow: 3px 4px 6px rgba(0,0,0,.135); }
.modalContent:focus-visible { outline: none !important; }
.modalClose { position: absolute; top: 21px; right: 21px; height: 50px; width: 50px; background: none; outline: none; border: none; cursor: pointer; }
.modalClose::after { content: '\f00d'; font-family: 'Font Awesome 5 Free'; font-weight: 900; padding-left: 14px; font-size: 1.25rem; }
.modalHeader { font-size: 1.5rem; font-weight: 500; color: rgba(0,0,0,.85); }
.ReactModal__Overlay { opacity: 0; transition: opacity 13ms ease-in-out; }
.ReactModal__Overlay--after-open{ opacity: 1; }
.ReactModal__Overlay--before-close{ opacity: 0; }
